<template>
  <div id="app">
    <Cashier />
  </div>
</template>

<script>
import Cashier from "./components/Cashier.vue";

export default {
  name: "App",
  components: {
    Cashier,
  },
};
</script>

<style>
* {
  margin: 0 !important;
  padding: 0 !important;
}

html {
  font-size: 12px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
