<template>
  <div>
    <div class="top" style="text-align: center">
      <h3 style="height:5rem;line-height:5rem;font-size:2rem;">Mgoogo Pay</h3>
      <!-- <img src="@assets/logo-kingpay.png" height="100rem" /> -->
    </div>

    <div class="main">
      <div class="select-panel" v-if="!processing">
        <div class="amount" style="text-align: center">
          <span style="font-size: 1.5rem !important">₹ </span>
          <span style="font-size: 3rem !important">{{ amount }}</span>
        </div>
        <div class="pay-desc">The amount you need to pay</div>

        <div class="pay-method">
          <div class="pay-select">Select an option to pay(UPI)</div>
          <div class="pay-type">
            <div class="pay-type-item" @click="choosePay('paytmmp')">
              <div class="pay-type-item-wrapper">
                <div class="pay-item-icon">
                  <img src="@assets/paytm.png" />
                </div>
                <div class="pay-item-name">Paytm</div>
              </div>

              <div class="option-icon">
                <img
                  src="@assets/arrow.png"
                  style="transform: rotate(-90deg); width: 1rem"
                />
              </div>
            </div>

            <div class="pay-type-item" @click="choosePay('phonepe')">
              <div class="pay-type-item-wrapper">
                <div class="pay-item-icon">
                  <img src="@assets/phonepe.png" />
                </div>
                <div class="pay-item-name">Phonepe</div>
              </div>
              <div class="option-icon">
                <img
                  src="@assets/arrow.png"
                  style="transform: rotate(-90deg); width: 1rem"
                />
              </div>
            </div>
            <div class="pay-type-item" @click="choosePay('gpay')">
              <div class="pay-type-item-wrapper">
                <div class="pay-item-icon">
                  <img src="@assets/googlepay.png" />
                </div>
                <div class="pay-item-name">GooglePay</div>
              </div>
              <div class="option-icon">
                <img
                  src="@assets/arrow.png"
                  style="transform: rotate(-90deg); width: 1rem"
                />
              </div>
            </div>
            <div class="pay-type-item" @click="choosePay('upi')">
              <div class="pay-type-item-wrapper">
                <div class="pay-item-icon">
                  <img src="@assets/upi.png" />
                </div>
                <div class="pay-item-name">UPI</div>
              </div>
              <div class="option-icon">
                <img
                  src="@assets/arrow.png"
                  style="transform: rotate(-90deg); width: 1rem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="procesing"
        v-if="processing"
        style="margin-top: 10rem !important"
      >
        <van-count-down millisecond :time="countdownTime" format="mm:ss">
          <template #default="timeData">
            <div style="text-align: center; font-size: 1.8rem">
              <span class="countdown-block">{{ timeData.minutes }}</span>
              <span class="countdown-colon">:</span>
              <span class="countdown-block">{{ timeData.seconds }}</span>
            </div>
          </template>
        </van-count-down>
        <p
          style="
            text-align: center;
            font-size: 1.5rem;
            margin-top: 1rem !important;
          "
        >
          If Your payment is in progress,
        </p>
        <p
          style="
            text-align: center;
            font-size: 1.5rem;
            margin-top: 1rem !important;
          "
        >
          Please wait and do not pay again.
        </p>
        <van-button
          style="margin-top: 2rem !important"
          type="info"
          block
          @click="reChoose()"
          >click me re-pay</van-button
        >
      </div>
    </div>

    <div class="bottom-tool">
      <p class="secured d-flex justify-content-center align-items-center">
        <img class="safe-box-icon" src="@assets/safe-icon.png" />
        <b>This payment is secured by UPI App Pay.</b>
      </p>
    </div>

    <van-overlay :show="loading" @click="show = false">
      <div class="overlay-wrapper">
        <van-loading size="4rem" text-color="#ffffff" vertical
          >Loading, patiently wait for a few seconds...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>
  
<script>
import axios from "axios";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      loading: true,
      processing: false,
      payInfo: {},
      returnUrl: "",
      status: 0,
      amount: "",
      countdownTime: 5 * 60 * 1000,
      sechdule: null,
    };
  },
  mounted() {
    // Create a FormValidation instance
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    let orderId = params.get("orderId");
    if (orderId != undefined && orderId != null && orderId != "") {
      this.getData(orderId);
    }
    this.sechdule = setInterval(() => {
      this.getData(orderId);
    }, 1000 * 2);
  },
  methods: {
    choosePay(payType) {
      let payLink = this.payInfo[payType];
      this.processing = true;
      window.location.href = payLink;
    },
    reChoose() {
      this.processing = false;
    },
    getData(orderId) {
      axios
        .get("/api/trade/cashier?orderId=" + orderId)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.payInfo = data.result.payInfo;
            this.returnUrl = data.result.returnUrl;
            this.status = data.result.status;
            this.amount = data.result.amount;
            this.loading = false;
            if (this.status == 4 || this.status == 6) {
              window.location.href = this.returnUrl;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  background: rgb(242, 201, 138);
}

.amount {
  margin: 1rem 0 0 0 !important;
  text-align: center;
  font-family: ArialMT;
  color: #435f98;
}

.pay-desc {
  margin: 0.2rem 0 0 0;
  font-size: 1rem;
  text-align: center;
  font-family: ArialMT;
  color: #a2a2a2;
}

.pay-method {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pay-select {
  margin: 1.5rem 0 0.5rem 0 !important;
  font-size: 1.32rem;
  font-family: ArialMT;
  color: #18284a;
}

.procesing {
  margin: 1.5rem 0 0.5rem 0 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.pay-type {
  margin: 0 0.33rem;
}

.pay-type-item {
  list-style: none;
  height: 4.6rem !important;
  line-height: 4.6rem;
  border: 0.1rem solid rgb(230, 234, 240);
  margin-top: 1rem !important;
  border-radius: 2.3rem;
  display: flex;

  align-content: center;
  justify-content: space-between;
  padding: 0rem 0.8rem 0rem 0.8rem !important;
}

.pay-type-item-wrapper div {
  display: inline-block !important;
}

.pay-item-icon img {
  vertical-align: middle;
  max-height: 3.5rem;
  max-width: 3.5rem;
}

.pay-item-name {
  vertical-align: middle;
  margin-left: 1rem !important;
  font-size: 1.6rem;
  font-family: ArialMT;
  color: rgba(24, 40, 74, 0.85);
  margin-left: 0.2rem;
}

.bottom-tool {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 19;
}

.bottom-tool .secured {
  margin: 1rem 0 !important;
}

.bottom-tool .secured .safe-box-icon {
  width: 10px;
  margin-top: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.bottom-tool .secured b {
  font-size: 1rem;
  color: #2b2b2b;
  opacity: 0.6;
}

.layui-layer-move {
  display: none;
  position: fixed;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: move;
  opacity: 0;
  filter: alpha(opacity=0);
  background-color: #fff;
  z-index: 2147483647;
}

.loading {
  margin: auto auto !important;
  width: 4rem;
  height: 4rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-left-color: #03a9f4;
  animation: loading 1s infinite linear;
}

.overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  