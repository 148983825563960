import Vue from 'vue'
import App from './App.vue'
import { Overlay } from 'vant';
import { Loading } from 'vant';
import { CountDown } from 'vant';
import { Button } from 'vant';
import 'vant/lib/index.css';

Vue.config.productionTip = false;
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(CountDown);
Vue.use(Button);

new Vue({
  render: h => h(App),
}).$mount('#app')
